var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"user-profile","fluid":"","tag":"section"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('base-material-card',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v(" Create New Users ")]),_c('div',{staticClass:"subtitle-1 font-weight-light"},[_vm._v(" Complete your profile ")])]},proxy:true}])},[_c('v-form',{model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[[_c('validation-observer',{ref:"observer"},[_c('form',[_c('validation-provider',{attrs:{"name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Masukan Nama","rules":_vm.emailRules,"error-messages":errors,"label":"Name","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}])}),_c('validation-provider',{attrs:{"name":"ID Anggota"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Masukan ID Anggota","error-messages":errors,"label":"ID Anggota","required":"","type":"email"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}])}),_c('validation-provider',{attrs:{"name":"PhoneNumber","rules":"required|max:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":15,"error-messages":errors,"placeholder":"Masukan No.Hp","label":"No.Phone","type":"number","required":""},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}])}),_c('validation-provider',{attrs:{"name":"Email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Masukan E-mail","error-messages":errors,"label":"E-mail","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}])}),_c('validation-provider',{attrs:{"name":"Keterangan Lain","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Tambahan Untuk Angkatan Atau Kelas","error-messages":errors,"label":"Keterangan Lain","required":""},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})]}}])}),_c('validation-provider',{attrs:{"name":"Region","rules":"required|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.region.data,"error-messages":errors,"label":"Region/Cabang","required":"","item-text":"name","item-value":"id"},model:{value:(_vm.region_id),callback:function ($$v) {_vm.region_id=$$v},expression:"region_id"}})]}}])}),_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","disabled":!_vm.isValid},on:{"click":function($event){$event.preventDefault();return _vm.add($event)}}},[_vm._v(" submit ")])],1)])]],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }