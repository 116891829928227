<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Create New Users
            </div>

            <div class="subtitle-1 font-weight-light">
              Complete your profile
            </div>
          </template>

          <v-form v-model="isValid">
            <template>
              <validation-observer ref="observer">
                <form>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Name"
                  >
                    <v-text-field
                      v-model="name"
                      placeholder="Masukan Nama"
                      :rules="emailRules"
                      :error-messages="errors"
                      label="Name"
                      required
                    />
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="ID Anggota"
                  >
                    <v-text-field
                      v-model="username"
                      placeholder="Masukan ID Anggota"
                      :error-messages="errors"
                      label="ID Anggota"
                      required
                      type="email"
                    />
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="PhoneNumber"
                    rules="required|max:15"
                  >
                    <v-text-field
                      v-model="phone"
                      :counter="15"
                      :error-messages="errors"
                      placeholder="Masukan No.Hp"
                      label="No.Phone"
                      type="number"
                      required
                    />
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="email"
                  >
                    <v-text-field
                      v-model="email"
                      placeholder="Masukan E-mail"
                      :error-messages="errors"
                      label="E-mail"
                      required
                    />
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Keterangan Lain"
                    rules="required"
                  >
                    <v-text-field
                      v-model="note"
                      placeholder="Tambahan Untuk Angkatan Atau Kelas"
                      :error-messages="errors"
                      label="Keterangan Lain"
                      required
                    />
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Region"
                    rules="required|"
                  >
                    <v-select
                      v-model="region_id"
                      :items="region.data"
                      :error-messages="errors"
                      label="Region/Cabang"
                      required
                      item-text="name"
                      item-value="id"
                    />
                  </validation-provider>

                  <v-btn
                    class="mr-4"
                    type="submit"
                    :disabled="!isValid"
                    @click.prevent="add"
                  >
                    submit
                  </v-btn>
                </form>
              </validation-observer>
            </template>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')
  export default {
    name: 'AnggotaCreate',
    data () {
      return {
        region_id: '',
        region: {
          data: [
            {
              name: '',
            },
          ],
          meta: {},
        },
        name: '',
        email: '',
        phone: '',
        username: '',
        note: '',
        isValid: false,
        emailRules: [value => value !== '' || 'Form Harus Di isi '],
      }
    },
    mounted () {
      this.getRegion()
    },
    methods: {
      setRegion (data) {
        this.region = data
        console.log(this.region)
      },
      getRegion () {
        axios.get('/v1/region?entities=community&sort=-id').then(response => {
          this.setRegion(response.data)
          console.log(response.data)
        })
      },
      add () {
        this.$store
          .dispatch('register', {
            name: this.name,
            email: this.email,
            phone: this.phone,
            username: this.username,
            note: this.note,
            region_id: this.region_id,
          })
          .then(response => {
            if (response.data.meta.status) {
              this.$router.push({
                path: '/anggota',
              })
              // update
              this.$toast.success('Berhasil Di Buat', {
                type: 'success',
                position: 'top-right',
                duration: 3000,
                dismissible: true,
              })
            } else {
              // update
              this.$toast.error('Gagal Di Buat', {
                type: 'error',
                position: 'top-right',
                duration: 3000,
                dismissible: true,
              })
            }
          })
          .catch(e => {
            console.error(e)
          })
      },
    },
  }
</script>

<style></style>
